import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useActions } from '../../../../hooks/use-actions'
import Button from '../../../ui/Button/Button'
import s from './FilterCreator.module.scss'
import LabelName from './LabelName/LabelName'
import Options from './OptionGM/OptionsGM'

export default function EditFilterGM() {
  const nav = useNavigate()
  const { addSavedQuery, editSavedQuery, resetQuery, editQuery } = useActions()
  const { addQuery, updateQuery } = useActions() // api
  const { savedQueries, query, user } = useSelector((state) => state.modalSlice)
    const noob = useSelector(state => state.modalSlice.noob)
    const tier = useSelector(state => state.modalSlice.tier)
    const cmb_tier = useSelector(state => state.modalSlice.cmb_tier)

  const isEdit = savedQueries.some((sq) => sq.id === query.id)
  console.log('isEdit', isEdit)
  const saveQuery = () => {
    if (!query.selected.length) {
      // return toast.error('Nothing selected')
      return toast.error(`NO OPTIONS WERE SELECTED`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })




    } else if (!query.name) {
      // return toast.error('Enter a name for this filter')
        return toast.error(`ENTER A NAME FOR THIS PROFILE`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })


    }

/*
    if (savedQueries.length > 0) {
      // return toast.error('Nothing selected')
      return toast.error('Free users are limited to one filter. Get some $CHECK for more.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
*/
    
    if (isEdit) {

      console.log('query', query)
      let data = {
        id: query.id,
        selected: query.selected,
        name: query.name,
        telegram_id: user.telegram_id,
        username: user.username,
        hash: user.hash,
        sim: query.sim

      }

      updateQuery(data)
      // editSavedQuery(query)
  


toast.success(`PROFILE '${query.name}' UPDATED. SCANNING MAINNET FOR MATCHES`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })


    } else {

      if (savedQueries.length > 4) {

        
        if (cmb_tier === 0) {

          toast.error(`LIMIT OF 3 SIGNALS FOR FREE USERS`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })





        } else {


             let data = {
            id: query.id,
            selected: query.selected,
            name: query.name,
            telegram_id: user.telegram_id,
            username: user.username,
            noob: noob,
            hash: user.hash
          }

          addQuery(data)
          // addSavedQuery(query)
      


        toast.success(`Profile '${query.name}' CREATED SCANNING MAINNET FOR MATCHES`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })



        }
   
      } else {

          let data = {
            id: query.id,
            selected: query.selected,
            name: query.name,
            telegram_id: user.telegram_id,
            username: user.username,
            noob: noob,
            hash: user.hash
          }

          addQuery(data)
          // addSavedQuery(query)



        toast.success(`Profile '${query.name}' CREATED, SCANNING MAINNET FOR MATCHES`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })

      }

    }
    resetQuery()
    nav('/free/show-filters')
  }

  return (
    <div className={s.container}>
    
   
      <Options />




    <div className={s.profileCard}>

          <div className={s.profileContainer}>
                    

   <input
      type="text"
    value={query.name}
      placeholder="PROFILE NAME"
      className={s.inputField2}
    onChange={(e) => editQuery({ ...query, name: e.target.value })}
    />

          </div>

          <div>
              <button className={s.saveButton} onClick={saveQuery}>
      {isEdit ? 'Update PROFILE' : 'CREATE PROFILE'}
    </button>

    </div>
        </div>


   





    </div>
  )
}