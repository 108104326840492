export const data = {
    getAllItems() {
        const items = []
        this.options.forEach((option) => items.push(...option.items))
        return items
    },
    options: [{
            id: 1,
            title: 'Contracts',
            items: [{
                    id: 1,
                    name: 'Verified',
                    desc: ''
                }
            ]
        },

        {
            id: 2,
            title: 'Initial Liquidity',
            items: [{
                    id: 24,
                    name: '1 ETH',
                    desc: ''
                },
                {
                    id: 25,
                    name: 'At least 2 ETH',
                    desc: ''
                },
                {
                    id: 26,
                    name: 'At least 3 ETH',
                    desc: ''
                }
            ]
        },

        {
            id: 3,
            title: 'Liquidity',
            items: [{
                    id: 3,
                    name: 'Burned',
                    desc: ''
                },
                {
                    id: 4,
                    name: 'Locked for a month',
                    desc: ''
                },
                {
                    id: 5,
                    name: 'Locked for 3 months',
                    desc: ''
                },
                {
                    id: 6,
                    name: 'Locked for 6 months',
                    desc: ''
                },
                {
                    id: 7,
                    name: 'Locked for a year',
                    desc: ''
                }
            ]
        },

        {
            id: 4,
            title: 'Funded Origin',
            items: [{
                    id: 12,
                    name: 'Exclude Fixed Float',
                    desc: ''
                },
                {
                    id: 13,
                    name: 'Exclude Binance',
                    desc: ''
                },
                {
                    id: 14,
                    name: 'Exclude Coinbase',
                    desc: ''
                },
                {
                    id: 15,
                    name: 'Exclude MEXC',
                    desc: ''
                },
                {
                    id: 16,
                    name: 'Exclude Non Exchanges',
                    desc: ''
                },
            ]
        },

        {
            id: 5,
            title: 'Snipers at launch',
            items: [{
                    id: 8,
                    name: 'At least 5',
                    desc: ''
                },
                {
                    id: 9,
                    name: 'At least 15',
                    desc: ''
                },
                {
                    id: 10,
                    name: 'At least 25',
                    desc: ''
                },
                {
                    id: 11,
                    name: 'More than 50',
                    desc: ''
                }
            ]
        },

        {
            id: 6,
            title: 'Socials Exists',
            items: [{
                    id: 17,
                    name: 'Telegram',
                    desc: ''
                },
                {
                    id: 18,
                    name: 'Twitter',
                    desc: ''
                },
                {
                    id: 19,
                    name: 'Website',
                    desc: ''
                }
            ]
        },

        {
            id: 6,
            title: 'Trending',
            items: [{
                    id: 20,
                    name: '@Trending VIA Safeguard',
                    desc: ''
                },
                {
                    id: 27,
                    name: '@ETHTRENDING VIA BuyTech',
                    desc: ''
                },
                {
          id: 29,
          name: 'Influencer Called',
          desc: ''
        },
            ]
        },

        {
            id: 7,
            title: 'Dextools',
            items: [{
                    id: 21,
                    name: 'Fast Tracked',
                    desc: ''
                },

                                {
                    id: 28,
                    name: 'Hot Pairs',
                    desc: ''
                },
            ]
        },
    ]
}