import React from 'react'

import { createBrowserRouter } from 'react-router-dom'
import Dashboard from './components/screens/Dashboard/Dashboard'
import Sync from './components/screens/Sync/Sync'

import FilterCreator from './components/screens/Modal/FilterCreator/FilterCreator'
import FilterCreatorGM from './components/screens/Modal/FilterCreator/FilterCreatorGM'
import EditFilterGM from './components/screens/Modal/EditFilter/EditFilterGM'
import GenerateWallet from './components/screens/Modal/GenerateWallet/GenerateWallet'
import GenerateCode from './components/screens/Modal/GenerateCode/GenerateCode'
import Modal from './components/screens/Modal/Modal'
import ModalLayout from './components/screens/Modal/ModalLayout'
import ShowBugs from './components/screens/Modal/ShowBugs/ShowBugs'
import ShowFilters from './components/screens/Modal/ShowFilters/ShowFilters'
import ShowFiltersGM from './components/screens/Modal/ShowFilters/ShowFiltersGM'
import Logs from './components/screens/Modal/Logs/Logs'
import Tokens from './components/screens/Modal/Tokens/Tokens'
import AutoBuyCreator from './components/screens/Modal/AutoBuyCreator/AutoBuyCreator'
import ShowBags from './components/screens/Modal/ShowBags/ShowBags'
import ShowBagsReal from './components/screens/Modal/ShowBagsReal/ShowBags'
import Main from './components/screens/Modal/Main'
import EditAutoBuy from './components/screens/Modal/EditAutoBuy/AutoBuyCreator'

import FreeFilterCreator from './components/screens/Free/FilterCreator/FilterCreator'
import FreeFilterCreatorGM from './components/screens/Free/FilterCreator/FilterCreatorGM'
import FreeEditFilterGM from './components/screens/Free/EditFilter/EditFilterGM'
import FreeGenerateWallet from './components/screens/Free/GenerateWallet/GenerateWallet'
import FreeGenerateCode from './components/screens/Free/GenerateCode/GenerateCode'
import FreeModal from './components/screens/Free/Modal'
import FreeModalLayout from './components/screens/Free/ModalLayout'
import FreeShowBugs from './components/screens/Free/ShowBugs/ShowBugs'
import FreeShowFilters from './components/screens/Free/ShowFilters/ShowFilters'
import FreeShowFiltersGM from './components/screens/Free/ShowFilters/ShowFiltersGM'
import FreeLogs from './components/screens/Free/Logs/Logs'
import FreeTokens from './components/screens/Free/Tokens/Tokens'
import FreeAutoBuyCreator from './components/screens/Free/AutoBuyCreator/AutoBuyCreator'
import FreeShowBags from './components/screens/Free/ShowBags/ShowBags'
import FreeShowBagsReal from './components/screens/Free/ShowBagsReal/ShowBags'
import FreeMain from './components/screens/Free/Main'
import FreeEditAutoBuy from './components/screens/Free/EditAutoBuy/AutoBuyCreator'

import BasicFilterCreator from './components/screens/Basic/FilterCreator/FilterCreator'
import BasicFilterCreatorGM from './components/screens/Basic/FilterCreator/FilterCreatorGM'
import BasicEditFilterGM from './components/screens/Basic/EditFilter/EditFilterGM'
import BasicGenerateWallet from './components/screens/Basic/GenerateWallet/GenerateWallet'
import BasicGenerateCode from './components/screens/Basic/GenerateCode/GenerateCode'
import BasicModal from './components/screens/Basic/Modal'
import BasicModalLayout from './components/screens/Basic/ModalLayout'
import BasicShowBugs from './components/screens/Basic/ShowBugs/ShowBugs'
import BasicShowFilters from './components/screens/Basic/ShowFilters/ShowFilters'
import BasicShowFiltersGM from './components/screens/Basic/ShowFilters/ShowFiltersGM'
import BasicLogs from './components/screens/Basic/Logs/Logs'
import BasicTokens from './components/screens/Basic/Tokens/Tokens'
import BasicAutoBuyCreator from './components/screens/Basic/AutoBuyCreator/AutoBuyCreator'
import BasicShowBags from './components/screens/Basic/ShowBags/ShowBags'
import BasicShowBagsReal from './components/screens/Basic/ShowBagsReal/ShowBags'
import BasicMain from './components/screens/Basic/Main'
import BasicEditAutoBuy from './components/screens/Basic/EditAutoBuy/AutoBuyCreator'

import ProFilterCreator from './components/screens/Pro/FilterCreator/FilterCreator'
import ProFilterCreatorGM from './components/screens/Pro/FilterCreator/FilterCreatorGM'
import ProEditFilterGM from './components/screens/Pro/EditFilter/EditFilterGM'
import ProGenerateWallet from './components/screens/Pro/GenerateWallet/GenerateWallet'
import ProGenerateCode from './components/screens/Pro/GenerateCode/GenerateCode'
import ProModal from './components/screens/Pro/Modal'
import ProModalLayout from './components/screens/Pro/ModalLayout'
import ProShowBugs from './components/screens/Pro/ShowBugs/ShowBugs'
import ProShowFilters from './components/screens/Pro/ShowFilters/ShowFilters'
import ProShowFiltersGM from './components/screens/Pro/ShowFilters/ShowFiltersGM'
import ProLogs from './components/screens/Pro/Logs/Logs'
import ProTokens from './components/screens/Pro/Tokens/Tokens'
import ProAutoBuyCreator from './components/screens/Pro/AutoBuyCreator/AutoBuyCreator'
import ProShowBags from './components/screens/Pro/ShowBags/ShowBags'
import ProShowBagsReal from './components/screens/Pro/ShowBagsReal/ShowBags'
import ProMain from './components/screens/Pro/Main'
import ProEditAutoBuy from './components/screens/Pro/EditAutoBuy/AutoBuyCreator'

import GodsEyeFilterCreator from './components/screens/GodsEye/FilterCreator/FilterCreator'
import GodsEyeFilterCreatorGM from './components/screens/GodsEye/FilterCreator/FilterCreatorGM'
import GodsEyeEditFilterGM from './components/screens/GodsEye/EditFilter/EditFilterGM'
import GodsEyeGenerateWallet from './components/screens/GodsEye/GenerateWallet/GenerateWallet'
import GodsEyeGenerateCode from './components/screens/GodsEye/GenerateCode/GenerateCode'
import GodsEyeModal from './components/screens/GodsEye/Modal'
import GodsEyeModalLayout from './components/screens/GodsEye/ModalLayout'
import GodsEyeShowBugs from './components/screens/GodsEye/ShowBugs/ShowBugs'
import GodsEyeShowFilters from './components/screens/GodsEye/ShowFilters/ShowFilters'
import GodsEyeShowFiltersGM from './components/screens/GodsEye/ShowFilters/ShowFiltersGM'
import GodsEyeLogs from './components/screens/GodsEye/Logs/Logs'
import GodsEyeTokens from './components/screens/GodsEye/Tokens/Tokens'
import GodsEyeAutoBuyCreator from './components/screens/GodsEye/AutoBuyCreator/AutoBuyCreator'
import GodsEyeShowBags from './components/screens/GodsEye/ShowBags/ShowBags'
import GodsEyeShowBagsReal from './components/screens/GodsEye/ShowBagsReal/ShowBags'
import GodsEyeMain from './components/screens/GodsEye/Main'
import GodsEyeEditAutoBuy from './components/screens/GodsEye/EditAutoBuy/AutoBuyCreator'

import MastercraftFilterCreator from './components/screens/Mastercraft/FilterCreator/FilterCreator'
import MastercraftFilterCreatorGM from './components/screens/Mastercraft/FilterCreator/FilterCreatorGM'
import MastercraftEditFilterGM from './components/screens/Mastercraft/EditFilter/EditFilterGM'
import MastercraftGenerateWallet from './components/screens/Mastercraft/GenerateWallet/GenerateWallet'
import MastercraftGenerateCode from './components/screens/Mastercraft/GenerateCode/GenerateCode'
import MastercraftModal from './components/screens/Mastercraft/Modal'
import MastercraftModalLayout from './components/screens/Mastercraft/ModalLayout'
import MastercraftShowBugs from './components/screens/Mastercraft/ShowBugs/ShowBugs'
import MastercraftShowFilters from './components/screens/Mastercraft/ShowFilters/ShowFilters'
import MastercraftShowFiltersGM from './components/screens/Mastercraft/ShowFilters/ShowFiltersGM'
import MastercraftLogs from './components/screens/Mastercraft/Logs/Logs'
import MastercraftTokens from './components/screens/Mastercraft/Tokens/Tokens'
import MastercraftAutoBuyCreator from './components/screens/Mastercraft/AutoBuyCreator/AutoBuyCreator'
import MastercraftShowBags from './components/screens/Mastercraft/ShowBags/ShowBags'
import MastercraftShowBagsReal from './components/screens/Mastercraft/ShowBagsReal/ShowBags'
import MastercraftMain from './components/screens/Mastercraft/Main'
import MastercraftEditAutoBuy from './components/screens/Mastercraft/EditAutoBuy/AutoBuyCreator'

// 5452240340

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />
  },
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    path: '/sync',
    element: <Sync />
  },
  {
    path: '/modal',
    children: [
      {
        element: <Modal />,
        index: true
      },
      {
        element: <ModalLayout />,
        children: [
          {
            path: 'create-filter',
            element: <FilterCreator />
          },
          {
            path: 'show-filters',
            element: <ShowFilters />
          },
          {
            path: 'generate-wallet',
            element: <GenerateWallet />
          },
          {
            path: 'generate-referral-code',
            element: <GenerateCode />
          },
          {
            path: 'logs',
            element: <Logs />
          },
          {
            path: 'show-bags',
            element: <ShowBags />
          },
          {
            path: 'show-bags-real',
            element: <ShowBagsReal />
          },
          {
            path: 'show-filters/:id/auto-buy',
            element: <AutoBuyCreator />
          },          
          {
            path: 'show-filters/:id/edit-auto-buy',
            element: <EditAutoBuy />
          },

             {
            path: 'create-filter-gm',
            element: <FilterCreatorGM />
          },

                       {
            path: 'edit-filter-gm',
            element: <EditFilterGM />
          },

          {
            path: 'show-filters-gm',
            element: <ShowFiltersGM />
          }

        ]
      }
    ]
  },
  {
    path: '/free',
    children: [
      {
        element: <FreeModal />,
        index: true
      },
      {
        element: <FreeModalLayout />,
        children: [
          {
            path: 'create-filter',
            element: <FreeFilterCreator />
          },
          {
            path: 'show-filters',
            element: <FreeShowFilters />
          },
          {
            path: 'generate-wallet',
            element: <FreeGenerateWallet />
          },
          {
            path: 'generate-referral-code',
            element: <FreeGenerateCode />
          },
          {
            path: 'logs',
            element: <FreeLogs />
          },
          {
            path: 'show-bags',
            element: <FreeShowBags />
          },
          {
            path: 'show-bags-real',
            element: <FreeShowBagsReal />
          },
          {
            path: 'show-filters/:id/auto-buy',
            element: <FreeAutoBuyCreator />
          },          
          {
            path: 'show-filters/:id/edit-auto-buy',
            element: <FreeEditAutoBuy />
          },

             {
            path: 'create-filter-gm',
            element: <FreeFilterCreatorGM />
          },

                       {
            path: 'edit-filter-gm',
            element: <FreeEditFilterGM />
          },

          {
            path: 'show-filters-gm',
            element: <FreeShowFiltersGM />
          }

        ]
      }
    ]
  },
  {
    path: '/basic',
    children: [
      {
        element: <BasicModal />,
        index: true
      },
      {
        element: <BasicModalLayout />,
        children: [
          {
            path: 'create-filter',
            element: <BasicFilterCreator />
          },
          {
            path: 'show-filters',
            element: <BasicShowFilters />
          },
          {
            path: 'generate-wallet',
            element: <BasicGenerateWallet />
          },
          {
            path: 'generate-referral-code',
            element: <BasicGenerateCode />
          },
          {
            path: 'logs',
            element: <BasicLogs />
          },
          {
            path: 'show-bags',
            element: <BasicShowBags />
          },
          {
            path: 'show-bags-real',
            element: <BasicShowBagsReal />
          },
          {
            path: 'show-filters/:id/auto-buy',
            element: <BasicAutoBuyCreator />
          },          
          {
            path: 'show-filters/:id/edit-auto-buy',
            element: <BasicEditAutoBuy />
          },

             {
            path: 'create-filter-gm',
            element: <BasicFilterCreatorGM />
          },

                       {
            path: 'edit-filter-gm',
            element: <BasicEditFilterGM />
          },

          {
            path: 'show-filters-gm',
            element: <BasicShowFiltersGM />
          }

        ]
      }
    ]
  },
  {
    path: '/pro',
    children: [
      {
        element: <ProModal />,
        index: true
      },
      {
        element: <ProModalLayout />,
        children: [
          {
            path: 'create-filter',
            element: <ProFilterCreator />
          },
          {
            path: 'show-filters',
            element: <ProShowFilters />
          },
          {
            path: 'generate-wallet',
            element: <ProGenerateWallet />
          },
          {
            path: 'generate-referral-code',
            element: <ProGenerateCode />
          },
          {
            path: 'logs',
            element: <ProLogs />
          },
          {
            path: 'show-bags',
            element: <ProShowBags />
          },
          {
            path: 'show-bags-real',
            element: <ProShowBagsReal />
          },
          {
            path: 'show-filters/:id/auto-buy',
            element: <ProAutoBuyCreator />
          },          
          {
            path: 'show-filters/:id/edit-auto-buy',
            element: <ProEditAutoBuy />
          },

             {
            path: 'create-filter-gm',
            element: <ProFilterCreatorGM />
          },

                       {
            path: 'edit-filter-gm',
            element: <ProEditFilterGM />
          },

          {
            path: 'show-filters-gm',
            element: <ProShowFiltersGM />
          }

        ]
      }
    ]
  },
  {
    path: '/godseye',
    children: [
      {
        element: <GodsEyeModal />,
        index: true
      },
      {
        element: <GodsEyeModalLayout />,
        children: [
          {
            path: 'create-filter',
            element: <GodsEyeFilterCreator />
          },
          {
            path: 'show-filters',
            element: <GodsEyeShowFilters />
          },
          {
            path: 'generate-wallet',
            element: <GodsEyeGenerateWallet />
          },
          {
            path: 'generate-referral-code',
            element: <GodsEyeGenerateCode />
          },
          {
            path: 'logs',
            element: <GodsEyeLogs />
          },
          {
            path: 'show-bags',
            element: <GodsEyeShowBags />
          },
          {
            path: 'show-bags-real',
            element: <GodsEyeShowBagsReal />
          },
          {
            path: 'show-filters/:id/auto-buy',
            element: <GodsEyeAutoBuyCreator />
          },          
          {
            path: 'show-filters/:id/edit-auto-buy',
            element: <GodsEyeEditAutoBuy />
          },

             {
            path: 'create-filter-gm',
            element: <GodsEyeFilterCreatorGM />
          },

                       {
            path: 'edit-filter-gm',
            element: <GodsEyeEditFilterGM />
          },

          {
            path: 'show-filters-gm',
            element: <GodsEyeShowFiltersGM />
          }

        ]
      }
    ]
  },
  {
    path: '/mastercraft',
    children: [
      {
        element: <MastercraftModal />,
        index: true
      },
      {
        element: <MastercraftModalLayout />,
        children: [
          {
            path: 'create-filter',
            element: <MastercraftFilterCreator />
          },
          {
            path: 'show-filters',
            element: <MastercraftShowFilters />
          },
          {
            path: 'generate-wallet',
            element: <MastercraftGenerateWallet />
          },
          {
            path: 'generate-referral-code',
            element: <MastercraftGenerateCode />
          },
          {
            path: 'logs',
            element: <MastercraftLogs />
          },
          {
            path: 'show-bags',
            element: <MastercraftShowBags />
          },
          {
            path: 'show-bags-real',
            element: <MastercraftShowBagsReal />
          },
          {
            path: 'show-filters/:id/auto-buy',
            element: <MastercraftAutoBuyCreator />
          },          
          {
            path: 'show-filters/:id/edit-auto-buy',
            element: <MastercraftEditAutoBuy />
          },

             {
            path: 'create-filter-gm',
            element: <MastercraftFilterCreatorGM />
          },

                       {
            path: 'edit-filter-gm',
            element: <MastercraftEditFilterGM />
          },

          {
            path: 'show-filters-gm',
            element: <MastercraftShowFiltersGM />
          }

        ]
      }
    ]
  }
])
