import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../../hooks/use-actions';
import { data } from '../filter-creator.gm.data';
import s from './Option.module.scss';

export default function OptionsGM() {
  return (
    <div className={s.container}>
     
      <div className={s.full}>
        {data.options.map((option, i) => (
          <ProfileCard key={i} item={option} />
        ))}



        <div className={s.profileCard}>
          <div className={s.profileHeader}>
            <div className={s.profileTitle}>SETTINGS</div>
          </div>
          <div className={s.profileContainer}>
            <SimulateAutoBuyToggle />
          </div>
          <div className={s.profileContainer}>
            <SimulateAutoSellToggle />
          </div>
        </div>


      </div>

    </div>
  );
}

const ProfileCard = ({ item }) => (

  <div className={s.profileCard}>
    <div className={s.profileHeader}>
      <div className={s.profileTitle}>{item.title}</div>
    </div>
    <div className={s.profileContainer}>
      {item.items.map((profileRow, idx) => (
        <ProfileRow key={idx} item={profileRow} />
      ))}
    </div>
  </div>




);


const SimulateAutoBuyToggle = () => {
  const { editQuery } = useActions();
  const query = useSelector((state) => state.modalSlice.query);

  console.log('query', query)
  // Update the global state for 'sim'
  const handleSimulateBuysToggle = () => {
    const newSimValue = !query.sim;
    editQuery({ ...query, sim: newSimValue });
  };

  return (
    <div className={s.profileRow}>
      <div className={`${s.value} ${s.bag}`}>SIMULATE AUTO BUY</div>
      <div className={`${s.value} ${s.highlight}`}>
        <div className={s.toggleSwitch}>
          <input
            type="checkbox"
            id="toggle-simulateBuys"
            className={s.toggleInput}
            checked={query.sim || false} // Default to false if undefined
            onChange={handleSimulateBuysToggle}
          />
          <label htmlFor="toggle-simulateBuys" className={s.toggleLabel}>
            <span className={s.toggleIcon}></span>
          </label>
        </div>
      </div>
    </div>
  );
};

const SimulateAutoSellToggle = () => {
  const { editQuery } = useActions();
  const query = useSelector((state) => state.modalSlice.query);

  // Sync toggle state with global state
  const isSimulateSellsEnabled = query.simulateSells || false;

  // Update both local and global state on toggle
  const handleSimulateSellsToggle = () => {
    const newValue = !isSimulateSellsEnabled;
    editQuery({ ...query, simulateSells: newValue });
  };

  return (
    <div className={s.profileRow}>
      <div className={`${s.value} ${s.bag}`}>SIMULATE AUTO SELLS</div>
      <div className={`${s.value} ${s.highlight}`}>
        <div className={s.toggleSwitch}>
          <input
            type="checkbox"
            id="toggle-simulateSells"
            className={s.toggleInput}
            checked={isSimulateSellsEnabled}
            onChange={handleSimulateSellsToggle}
          />
          <label htmlFor="toggle-simulateSells" className={s.toggleLabel}>
            <span className={s.toggleIcon}></span>
          </label>
        </div>
      </div>
    </div>
  );
};

const ProfileRow = ({ item }) => {
  const { editQuery } = useActions();
  const query = useSelector((state) => state.modalSlice.query);

  const handleToggle = () => {
    const updatedQuery = query.selected.includes(item.id)
      ? query.selected.filter((id) => id !== item.id)
      : [...query.selected, item.id];
    editQuery({ ...query, selected: updatedQuery });
  };

  const isChecked = query.selected.includes(item.id);

  return (
    <div className={s.profileRow}>
      <div className={`${s.value} ${s.bag}`}>{item.name}</div>
      <div className={`${s.value} ${s.highlight}`}>
        <div className={s.toggleSwitch}>
          <input
            type="checkbox"
            id={`toggle-${item.id}`}
            className={s.toggleInput}
            checked={isChecked}
            onChange={handleToggle}
          />
          <label htmlFor={`toggle-${item.id}`} className={s.toggleLabel}>
            <span className={s.toggleIcon}></span>
          </label>
        </div>
      </div>
    </div>
  );
};
