import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useActions } from '../../../../hooks/use-actions'
import Button from '../../../ui/Button/Button'
import s from './FilterCreator.module.scss'
import LabelName from './LabelName/LabelName'
import Options from './OptionGM/OptionsGM'

export default function FilterCreatorGM() {
  const nav = useNavigate()
  const { addSavedQuery, editSavedQuery, resetQuery, editQuery } = useActions()
  const { addQuery, updateQuery } = useActions() // api
  const { savedQueries, query, user } = useSelector((state) => state.modalSlice)
    const noob = useSelector(state => state.modalSlice.noob)
    const tier = useSelector(state => state.modalSlice.tier)
    const cmb_tier = useSelector(state => state.modalSlice.cmb_tier)

  const isEdit = savedQueries.some((sq) => sq.id === query.id)

  const saveQuery = () => {
    if (!query.selected.length) {
      // return toast.error('Nothing selected')
      return toast.error('Nothing selected', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (!query.name) {
      // return toast.error('Enter a name for this filter')
        return toast.error('Enter a name for this profile', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (query.selected.includes(3) && (query.selected.includes(4) || query.selected.includes(5) || query.selected.includes(6) || query.selected.includes(7))) {

      return toast.error(`It doesn't make sense to have both burned and locked. Please revise your profile`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })

    }

/*
    if (savedQueries.length > 0) {
      // return toast.error('Nothing selected')
      return toast.error('Free users are limited to one filter. Get some $CHECK for more.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
*/
    
    if (isEdit) {

      let data = {
        id: query.id,
        selected: query.selected,
        name: query.name,
        telegram_id: user.telegram_id,
        username: user.username,
        hash: user.hash
      }

      updateQuery(data)
      // editSavedQuery(query)
      toast.success('Profile Updated', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else {

      if (savedQueries.length > 4) {

        
        if (cmb_tier === 0) {

          toast.error('Limit of 5 profile for Premium users. God mode allows unlimited filters.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          })

        } else {


             let data = {
            id: query.id,
            selected: query.selected,
            name: query.name,
            telegram_id: user.telegram_id,
            username: user.username,
            noob: noob,
            hash: user.hash
          }

          addQuery(data)
          // addSavedQuery(query)
          toast.success('Profile Created. Scanning Mainnet for matches!', {
            position: toast.POSITION.BOTTOM_RIGHT
          })


        }
   
      } else {

          let data = {
            id: query.id,
            selected: query.selected,
            name: query.name,
            telegram_id: user.telegram_id,
            username: user.username,
            noob: noob,
            hash: user.hash
          }

          addQuery(data)
          // addSavedQuery(query)
          toast.success('Profile Created. Scanning Mainnet for matches!', {
            position: toast.POSITION.BOTTOM_RIGHT
          })

      }

    }
    resetQuery()
    nav('/free/show-filters')
  }

  return (
    <div className={s.container}>
    
      <div className={s.title}>
        {isEdit ? 'Update profile' : 'Create a new profile g'}
      </div>
      <Options />
    <div className={s.footer}>
        <LabelName
          name={query.name}
          className="x"
          setName={(name) => editQuery({ ...query, name })}
        />
        <Button onClick={saveQuery}>{isEdit ? 'Update' : 'Save'}</Button>
      </div>
    </div>
  )
}