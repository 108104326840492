import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useActions } from '../../../../hooks/use-actions'
import Button from '../../../ui/Button/Button'
import styles from './AutoBuyCreator.module.scss'
import LabelName from './LabelName/LabelName'
import Options from './Option/Options'
import Wallets from './Wallet/Wallets'

export default function AutoBuyCreator() {
    const nav = useNavigate()
    const { addSavedQuery, editSavedQuery, resetQuery, editQuery } = useActions()
    const { addQuery, updateQuery, editAutoBuyQuery, addAutoBuy, updateAutoBuy } = useActions() // api
    const { savedQueries, query, autobuy, user, autobuyQuery, wallets } = useSelector((state) => state.modalSlice)
    const { id } = useParams()

    console.log('savedQueries', savedQueries)
    const isEdit = savedQueries.some((sq) => {
        if (sq.autobuy) {
            return sq.autobuy.some((ab) => ab.id === autobuy.id);
        }
        return false;
    });

    console.log('isEdit', isEdit)

    useEffect(() => {

    }, []);


    const saveQuery = () => {

        console.log('wallets', wallets)
        console.log('autobuy', autobuy)

        if (wallets.length < 1) {
            return toast.error(`PLEASE GENERATE A WALLET`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })} else if (!autobuy.wallets.length) {
            return toast.error(`NO WALLET SELECTED`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })


        } else if (!autobuy.eth_amount) {
             return toast.error(`MISSING ETH AMOUNT`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })
        } else if (!autobuy.name) {
             return toast.error(`MISSING AUTOBUY NAME`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })

        } else if (autobuy.eth_amount < 0.00001) {
             return toast.error(`ETH AMOUNT MUST BE GREATER THAN 0.00001`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })
        } else if (!autobuy.max_slippage) {
             return toast.error(`MISSING MAX SLIPPAGE`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })
        } else if (!autobuy.max_gas) {
             return toast.error(`MISSING MAX GAS`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })
        } else if (id == undefined || id === null) {
            return toast.error('Filter ID is empty')
        }



        // nav('/modal/show-filters')


        if (isEdit) {



            editAutoBuyQuery({
                ...autobuy,
                filterId: id
            })

            // console.log('autobuy', autobuy)
            updateAutoBuy({ autobuy, telegram_id: user.telegram_id })
           // toast.success('AutoBuy Updated')

    toast.success(`AUTOBUY UPDATED`, {
      style: {
        fontFamily: 'Proto Mono',
        fontSize: '13px',
        textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
      },
      position: toast.POSITION.TOP_RIGHT

    })


        } else {



            console.log('autobuy', autobuy)


            editAutoBuyQuery({
                ...autobuy,
                filterId: id
            })

            console.log('save autobuy')
            addAutoBuy({ autobuy, telegram_id: user.telegram_id })
            // toast.success('AutoBuy Added')
    toast.success(`AUTOBUY CREATED`, {
      style: {
        fontFamily: 'Proto Mono',
        fontSize: '13px',
        textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
      },
      position: toast.POSITION.TOP_RIGHT

    })

        }
        // resetQuery()
        nav('/pro/show-filters')

    }

    // Sync toggle state with global state
    const isAutoSellEnabled = autobuy.autosell || false;

    // Update both local and global state on toggle
    const handleAutoSellToggle = () => {
        const newValue = !isAutoSellEnabled;
        editAutoBuyQuery({ ...autobuy, autosell: newValue });
    };

    // Sync toggle state with global state
    const isAutoSellProtectionEnabled = autobuy.autosell_protection || false;

    // Update both local and global state on toggle
    const handleAutoSellProtectionToggle = () => {
        const newValue = !isAutoSellProtectionEnabled;
        editAutoBuyQuery({ ...autobuy, autosell_protection: newValue });
    };

    return (
        <div className={styles.autoBuyContainer}>

{/*}
      <div className={s.title}>
        {isEdit ? `Update Autobuy: ${autobuy.name}` : `Create Autobuy on profile: ${autobuy.filterName}`}
      </div>

      */}
      <Wallets />

      <div className={styles.settingsContainer}>

        <div className={styles.settingsCard}>

          <div className={styles.settingsHeader}>  
            <div className={styles.settingsTitle}>SETTINGS</div>
          </div>

          <div className={styles.settingsContainer}>
            


            <div className={styles.settingsRow}>
                
              <div className={styles.value}>SLIPPAGE LIMIT (buy)</div>
              <div> 
                <input
                  type="text"
                  value={autobuy.max_slippage}
                  onChange={(event) => editAutoBuyQuery({ ...autobuy, max_slippage: event.target.value })}
                  placeholder="15"
                  className={styles.inputField2}
                />
              </div>
            
            </div>

            <div className={styles.settingsRow}>
              
              <div className={styles.value}>GAS LIMIT</div>
              <div>  
                <input
                  type="text"
                  value={autobuy.max_gas}
                  onChange={(event) => editAutoBuyQuery({ ...autobuy, max_gas: event.target.value })}
                  placeholder="30"
                  className={styles.inputField2}
                />
              </div>
            
            </div>
            
            <div className={styles.settingsRow}>
              
              <div className={styles.value}>PRIORITY FEE</div>
              <div>  
                <input
                  type="text"
                  value={autobuy.max_priority}
                  onChange={(event) => editAutoBuyQuery({ ...autobuy, max_priority: event.target.value })}
                  placeholder="30"
                  className={styles.inputField2}
                />
              </div>
            
            </div>

            <div className={styles.settingsRow}>
              
              <div className={styles.value}>ETH AMOUNT</div>
              <div>  
                <input
                  type="text"
                  value={autobuy.eth_amount}
                  onChange={(event) => editAutoBuyQuery({ ...autobuy, eth_amount: event.target.value })}
                  placeholder="0.25"
                  className={styles.inputField2}
                />
              </div>
              
            </div>


            

          
          </div>
        
        </div>
      
    </div>

      <div className={styles.settingsContainer}>

        <div className={styles.settingsCard}>

          <div className={styles.settingsHeader}>  
            <div className={styles.settingsTitle}>AUTO SELL SETTINGS</div>
          </div>

          <div className={styles.settingsContainer}>
            



<div className={styles.settingsRow}>
  <div className={styles.value}>ENABLE AUTO SELL</div>
  <div>
    <div className={styles.toggleSwitch}>
      <input
        type="checkbox"
        id="toggle-autoSell"
        className={styles.toggleInput}
        checked={isAutoSellEnabled}
        onChange={handleAutoSellToggle}
      />
      <label htmlFor="toggle-autoSell" className={styles.toggleLabel}>
        <span className={styles.toggleIcon}></span>
      </label>
    </div>
  </div>
</div>


<div className={styles.settingsRow}>
  
  <div className={styles.value}>SELL WHEN % PROFIT</div>
  <div>  
    <input
      type="text"
      value={autobuy.autosell_at}
      onChange={(event) => editAutoBuyQuery({ ...autobuy, autosell_at: event.target.value })}
      placeholder="100"
      className={styles.inputField2}
    />
  </div>
  
</div>

<div className={styles.settingsRow}>
  
  <div className={styles.value}>GAS MAX</div>
  <div>  
    <input
      type="text"
      value={autobuy.autosell_tip}
      onChange={(event) => editAutoBuyQuery({ ...autobuy, autosell_gas: event.target.value })}
      placeholder="0.00001"
      className={styles.inputField2}
    />
  </div>

</div>


<div className={styles.settingsRow}>
    
  <div className={styles.value}>SLIPPAGE</div>
  <div> 
    <input
      type="text"
      value={autobuy.autosell_slippage}
      onChange={(event) => editAutoBuyQuery({ ...autobuy, autosell_slippage: event.target.value })}
      placeholder="30"
      className={styles.inputField2}
    />
  </div>

</div>


<div className={styles.settingsRow}>
    
  <div className={styles.value}>PRIORITY FEE</div>
  <div> 
    <input
      type="text"
      value={autobuy.autosell_priority}
      onChange={(event) => editAutoBuyQuery({ ...autobuy, autosell_priority: event.target.value })}
      placeholder="20"
      className={styles.inputField2}
    />
  </div>

</div>





{/*
<div className={styles.settingsRow}>
              
  <div className={styles.value}>PNL %</div>
  <div>  
    <input
      type="text"
      value={autobuy.auto_sell_pnl_min}
      onChange={(event) => editAutoBuyQuery({ ...autobuy, auto_sell_pnl_min: event.target.value })}
      placeholder="100"
      className={styles.inputField2}
    />
  </div>
  
</div>   
*/}

          
          </div>
        
        </div>
      
    </div>






      <div className={styles.settingsContainer}>

        <div className={styles.settingsCard}>

          <div className={styles.settingsHeader}>  
            <div className={styles.settingsTitle}>STOP LOSS SETTINGS</div>
          </div>

          <div className={styles.settingsContainer}>
            



<div className={styles.settingsRow}>
  <div className={styles.value}>ENABLE STOP LOSS</div>
  <div>
    <div className={styles.toggleSwitch}>
      <input
        type="checkbox"
        id="toggle-autoSellProtection"
        className={styles.toggleInput}
        checked={isAutoSellProtectionEnabled}
        onChange={handleAutoSellProtectionToggle}
      />
      <label htmlFor="toggle-autoSellProtection" className={styles.toggleLabel}>
        <span className={styles.toggleIcon}></span>
      </label>
    </div>
  </div>
</div>


<div className={styles.settingsRow}>
  
  <div className={styles.value}>SELL WHEN % DIP</div>
  <div>  
    <input
      type="text"
      value={autobuy.autosell_limit}
      onChange={(event) => editAutoBuyQuery({ ...autobuy, autosell_limit: event.target.value })}
      placeholder="-30"
      className={styles.inputField2}
    />
  </div>
  
</div>







          
          </div>
        
        </div>
      
    </div>



     <div className={styles.settingsContainer}>

        <div className={styles.settingsCard}>


          <div className={styles.settingsContainer}>
            


                <div className={styles.settingsRow}>
    <input
      type="text"
      value={autobuy.name}
      placeholder="autobuy name"
      className={styles.inputField}
      onChange={(event) => editAutoBuyQuery({ ...autobuy, name: event.target.value })}
    />
    </div>

          </div>
        
  <div className={styles.settingsRow}>

            <button className={styles.saveButton} onClick={saveQuery}>
      {isEdit ? 'Update autobuy' : 'SAVE autobuy'}
    </button>

    </div>
        </div>
      
    </div>
   




  </div>
    )
}